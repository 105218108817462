import React, { Component } from "react";
import {
  Button,
  Card,
  Carousel,
  CarouselItem,
  Col,
  Container,
  FormControl,
  Row,
} from "react-bootstrap";
import background from "../../images/c1-back.webp";
import axios from "axios";
import cogoToast from "cogo-toast";
import ModalVideo from "react-modal-video";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import bgVideo from "../../images/bg_video.mp4";
import ReCAPTCHA from "react-google-recaptcha";
class C1 extends Component {
  recaptchaRef = React.createRef();
  state = {
    phone: "",
    isOpen: false,
    activeIndex: this.props.data?.activeCarousel ?? 0,
  };
  componentDidMount() {}
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  sendLink = async () => {
    var phone = this.state.phone;
    if (this.state.phone.length === 10) {
      const captchaToken = await this.recaptchaRef?.current?.executeAsync();
      this.recaptchaRef?.current?.reset();
      axios
        .post(
          "/send-link",
          {
            mobile_number: `91${this.state.phone}`,
            recaptcha_token: captchaToken,
            name: "app_invite_link",
            attributes: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            cogoToast.success("Link sent to your mobile number via SMS");
            this.setState({ phone: "" });
          } else {
            cogoToast.error("Error");
          }
        })
        .catch((err) => {
          cogoToast.error(err);
        });
    } else {
      cogoToast.error("Please enter a valid mobile number");
    }
  };
  onIndexChange = (key, direction) => {
    this.setState({
      activeIndex: key,
    });
  };
  render() {
    const carousel = this.props.data?.carousel;
    const activeCarousel = this.props.data?.activeCarousel;

    if (!carousel) return <></>;

    return (
      <Container
        fluid
        id="home"
        className="c1 c-pad"
        style={{
          paddingBottom: "16px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Carousel
          // defaultActiveIndex={parseInt(activeCarousel) ?? 0}
          wrap={true}
          fade={true}
          pause={"hover"}
          controls={false}
          indicators={true}
          interval={5000}
          onSlid={this.onIndexChange}
          className="main-banner-carousel d-md-block"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <CarouselItem
            style={{
              zIndex: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <video
              className="video-player bg-video-sm d-none d-md-block"
              height="auto"
              width="100%"
              loop
              muted
              autoPlay
              style={{
                aspectRatio: 0.9,
              }}
            >
              <source src={bgVideo} type="video/mp4" sizes="lg" />
            </video>
            <div
              style={{
                backgroundColor: "rgb(0, 0, 0, 0.5)",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
              }}
              className="d-none d-md-block"
            ></div>
          </CarouselItem>
        </Carousel>
        <Row className="h-100" style={{ position: "relative" }}>
          <Col
            className="d-flex flex-column justify-content-end col-12 col-lg-6"
            style={{ paddingBottom: "60px" }}
          >
            <div className="c1-active-text">
              <div
                style={{
                  margin: "120px auto 24px auto",
                  borderRadius: "24px",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="d-block d-md-none"
                  src={background}
                  width={"100%"}
                  height={"auto"}
                  style={{
                    borderRadius: "24px",
                  }}
                  onClick={() => {
                    this.setState({ isOpen: true });
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "48px",
                    height: "48px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  className="d-block d-md-none play-btn-container"
                  onClick={() => {
                    this.setState({ isOpen: true });
                  }}
                >
                  <div className="play-btn">
                    <FontAwesomeIcon
                      style={{ margin: "auto", color: "#8a27f7" }}
                      icon={faPlay}
                    />
                  </div>
                </div>
              </div>
              <h1 className="c1-h1 mb-5">
                {carousel[1].title.split("\n")[0]}
                <br className="c1-br" />
                {carousel[1].title.split("\n")[1]}
              </h1>
              <div className="c1-h2">{carousel[1].subtitle}</div>
              <div
                style={{
                  marginBottom: "36px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {carousel[1].description}
              </div>
              <Card
                style={{
                  borderRadius: "30px",
                  paddingLeft: "24px",
                  marginBottom: "40px",
                  width: "100%",
                  maxWidth: "520px",
                }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex"
                    style={{ color: "#1E0E62", alignItems: "center" }}
                  >
                    +91
                  </div>
                  <span
                    style={{
                      backgroundColor: "#1E0E62",
                      width: "2px",
                      height: "34%",
                      marginInline: "10px",
                    }}
                  ></span>
                  <FormControl
                    placeholder="9999999999"
                    aria-label=""
                    aria-describedby="basic-addon1"
                    style={{
                      border: "none",
                      marginLeft: "-10px",
                      paddingLeft: "10px",
                    }}
                    type="number"
                    maxLength="10"
                    onChange={this.handleInput}
                    name="phone"
                    value={this.state.phone}
                  />
                  <Button
                    className="btn-gradient s-link"
                    onClick={this.sendLink}
                  >
                    Get link
                  </Button>
                </div>
              </Card>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={"6LfGaXknAAAAADKEdjejoqFVKcgrHIc4-RxT70wT"}
                size="invisible"
              />
            </div>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center col-lg-6"
            onClick={() => {
              this.setState({ isOpen: true });
            }}
          >
            <div
              className="d-none d-md-block play-btn-container"
              onClick={() => {
                this.setState({ isOpen: true });
              }}
            >
              <div className="play-btn">
                <FontAwesomeIcon
                  style={{ margin: "auto", color: "#8a27f7" }}
                  icon={faPlay}
                />
              </div>
            </div>
          </Col>
        </Row>
        {this.state.isOpen ? (
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId="uNTEqEBqxYg"
            onClose={() => this.setState({ isOpen: false })}
            autoplay
          />
        ) : null}
      </Container>
    );
  }
}

export default C1;
